<template>
  <!-- 实验室 -->
  <div class="content_box">
    <!-- 左侧历史列表 -->
    <div class="leftMenu">
      <div class="leftBordBox">
        <!-- 顶部切换 -->
        <div class="menuTop">
          <lbButton icon="xinzeng" class="addNewDialog" fill @click="addDialog">新建对话</lbButton>
          <lbButton icon="zhinangtuan_x" class="getWisdom" fill type="white" @click="addBrain"></lbButton>
        </div>
        <!-- 历史对话，参谋 -->
        <div class="menuTab">
          <el-tabs v-model="nowPanlActiveName" @tab-click="handleClick" stretch>
            <el-tab-pane class="tabPane" label="历史对话" name="common">
              <template v-if="allHistoryChatRoom.length > 0">
                <ul class="historyList ">
                  <li v-for="(item, index) in allHistoryChatRoom" :key="index"
                    :class="['hitoryChat', activeChatNum === index ? 'activeChat' : '']">
                    <div class="completed" v-if="!isEditTile || (activeChatNum !== index)"
                      @click="chooseHistoryChat(item, index)">
                      <div class="leftIcon">
                        <i class="icon iconfont lebo-duihuaicon"></i>
                      </div>
                      <div class="chatTitle">
                        {{ item.dialogu_name }}
                      </div>
                      <div class="editIcon">
                        <i class="icon iconfont lebo-xiugai1 edit" @click="(e) => editTitle(e, item, index)"></i>
                      <el-popconfirm
                            confirm-button-text='好的'
                            cancel-button-text='不用了'
                            icon="el-icon-info"
                            icon-color="red"
                            title="确定删除对话吗？"
                            @confirm="delDialog(item)"
                          >
                          <i class="icon iconfont lebo-shanchu1 del"  slot="reference" @click.stop></i>
                      </el-popconfirm>
                      </div>
                    </div>
                    <!-- 编辑历史标题 -->
                    <div class="editing" v-if="isEditTile && (activeChatNum === index)">
                      <el-input v-model="editingValue" :placeholder="item.dialogu_name" class="editInput" @blur="blurInput()" :id="'editInput' + index" >
                        <i slot="suffix" class="el-icon-check" @mousedown="checkEdit(editingValue,item)"></i>
                        <i slot="suffix" class="el-icon-close" @mousedown="blurInput(editingValue)"></i>
                      </el-input>
                    </div>
                  </li>
                </ul>
              </template>
              <template v-else>
                <div class="emptyDialog">
                </div>
              </template>
            </el-tab-pane>
            <el-tab-pane label="参谋列表" class="tabPane" name="brain">
              <template v-if="allBrainChatRoom.length>0">
                <ul class="historyList ">
                  <li v-for="(item, index) in allBrainChatRoom" :key="index"
                    :class="['hitoryBrain', activeBrainNum === index ? 'activeChat' : '']" @click="chooseBrainChat(item,index)">
                    <div class="leftPhoto">
                      <el-image class="upLoadImg" fit="cover" :src="item.head_photo">
                      </el-image>
                    </div>
                    <div class="rightDescribe">
                      <div class="rightDescribeTop">
                        {{ item.staff_name }}
                      </div>
                      <div class="rightDescribeBottom">
                        {{ item.dialogu_name  }}
                      </div>
                    </div>
                    <div class="editBox">
                      <el-popconfirm
                            confirm-button-text='好的'
                            cancel-button-text='不用了'
                            icon="el-icon-info"
                            icon-color="red"
                            title="确定删除参谋吗？"
                            @confirm="delBrainDialog(item)"
                          >
                          <i class="icon iconfont lebo-shanchu1 del"  slot="reference" @click.stop></i>
                      </el-popconfirm>
                    </div>
                  </li>
                </ul>
              </template>
              <template v-else>
                <div class="emptyBrain">
                </div>
                <lbButton icon="chaxun" class="addNewDialog" fill @click="addBrain"> 找 一 个 </lbButton>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- 右侧对话路由地址 -->
    <div class="rightDetial">
      <router-view />
    </div>
  </div>
</template>
<script>
import { getHistoryChatList, editeDialogTitle, delHistoryDialog } from '@/api/juewuApi.js'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

export default {
  data () {
    return {
      activeName: 'common',
      historyTalkList: [],
      activeHistoryChat: 0, // 当前点击历史对话
      isEditTile: false,
      delVisible: false,
      editingValue: '', // 编辑时的内容
      queryForm: {
        PageIndex: 1,
        PageSize: 20
      }
    }
  },
  created () {

  },

  mounted () {
    this.fnGetHistoryChatList()
    this.fngetBrainRoomList()
  },
  computed: {
    ...mapState('juewuStore', ['allHistoryChatRoom', 'activeChatNum', 'activeBrainNum', 'allBrainChatRoom', 'nowPanlActiveName']),
    ...mapGetters('juewuStore', ['nowPanlActiveName']),
    nowPanlActiveName: {
      get () {
        return this.$store.getters['juewuStore/nowPanlActiveName']
      },
      set (newValue) {
        this.$store.commit('juewuStore/changeNowPanlActiveName', newValue)
      }

    }
  },
  destroyed () {
  },
  watch: {
  },
  methods: {
    ...mapMutations('juewuStore', ['getAllHistoryRoom', 'changeActiveNum', 'changeBrainActiveNum']),
    // 获取历史对话列表
    async fnGetHistoryChatList () {
      this.$store.dispatch('juewuStore/fnGetHistoryChatList')
      // const res = await getHistoryChatList(this.queryForm)
      // console.log('历史对话列表', res)
      // if (res && res.Code === 200) {
      //   this.historyTalkList = res.Data
      // }
    },
    // 获取参谋历史对话列表
    async fngetBrainRoomList () {
      this.$store.dispatch('juewuStore/fngetBrainRoomList')
    },
    // 对话列表切换
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    // 新增对话
    addDialog () {
      this.changeActiveNum('')
      this.$router.push('/miracle-chat')
    },
    // 新增智囊团
    addBrain () {
      this.changeBrainActiveNum('')
      this.$router.push('/add-brain-trust')
    },
    // 选择历史对话
    chooseHistoryChat (item, index) {
      // this.activeHistoryChat = index
      this.changeActiveNum(index)
      // console.log('历史对话', item)
      this.$router.push({
        // name: 'commoneChat',
        path: '/commone-chat?id=' + item._id,
        params: {
          id: item._id
        }
      })
    },
    // 修改历史对话标题
    editTitle (e, item, index) {
      e.stopPropagation()
      this.changeActiveNum(index)
      this.editingValue = ''
      this.editingValue = item.dialogu_name
      this.isEditTile = true
      this.$nextTick(() => {
        const catchId = 'editInput' + index
        const editingBox = document.getElementById(catchId)
        editingBox.focus()
      })
    },
    // 确认编辑
    checkEdit (context, item) {
      this.fnEditeDialogTitle(context, item)
      this.isEditTile = false
    },
    // 取消编辑
    refuseEdit () {

    },
    // 完成编辑失去焦点
    blurInput (item) {
      this.isEditTile = false
    },
    // 发起编辑历史记录问题标题请求
    async fnEditeDialogTitle (context, item) {
      const res = await editeDialogTitle({
        _id: item._id,
        dialogu_name: context
      })
      if (res && res.Code === 200) {
        this.fnGetHistoryChatList()
      }
    },
    // 删除历史对话
    async delDialog (item) {
      // this.deVisible = true
      // e.stopPropagation()

      const res = await delHistoryDialog({ _id: item._id })
      if (res && res.Code === 200) {
        this.fnGetHistoryChatList()
      }
    },
    // 删除参谋
    async  delBrainDialog (item) {
      console.log('item', item)
      const res = await delHistoryDialog({ _id: item._id })
      if (res && res.Code === 200) {
        this.fngetBrainRoomList()
      }
    },
    // 选择参谋对话
    chooseBrainChat (item, index) {
      console.log('参谋对话', item)
      this.changeBrainActiveNum(index)
      this.$router.push({
        // name: 'commoneChat',
        path: '/brain-chat?id=' + item._id + '&staff_id=' + item.staff_id,
        params: {
          id: item._id,
          brainDetail: item
        }
      })
    },
    showInfo (e, index) {
      this.deVisible = true
      e.stopPropagation()
      const key = 'popover' + index
      // document.getElementById(this.$refs['popover_' + index].$refs.popper.id).style.display = 'none'
      // this.$nextTick(() => {
      //   this.$refs[key][0].$refs.popper.previousSibling.style.display = 'none'
      // })
    }

  }
}
</script>
<style scoped lang="less">
.content_box {
  width: 100%;
  height: 100%;
  background-color: #F2F2F2;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;

  .leftMenu {
    // width: 20%;
    width: 400px;
    height: 100%;
    padding: 10px;
    // padding-right: 0;
    box-sizing: border-box;

    .leftBordBox {
      width: 100%;
      height: 100%;
      background-color: #fff;

      .menuTop {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 8%;

        .addNewDialog {
          width: 200px;
        }

        /deep/.getWisdom {
          border: 1px solid #01A6FB;
          i {
            font-weight: 500;
            font-size: 16px;
            margin-right: unset;
            color: #01A6FB;
          }
        }
      }

      .menuTab {
        /deep/.el-tabs__active-bar.is-top {
          padding: 0 35px;
          box-sizing: border-box !important;
          background-clip: content-box !important;
        }

        .tabPane {
          height: 100%;
          .historyList {
            overflow: hidden;
            overflow-y: auto;
            max-height: 720px;
            height: 100%;
            // padding:0 15px;
            padding-left: 10px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: block;
            .activeChat {
              border: 1px solid #01A6FB !important;
              .leftIcon {
                color: #01A6FB;
              }

              .chatTitle {
                color: #01A6FB;
              }
            }
            .hitoryChat {
              width: 280px;
              height: 50px;
              margin-bottom: 10px;
              background: #FFFFFF;
              border-radius: 5px 5px 5px 5px;
              border: 1px solid #E6E6E6;

              .completed {
                height: 100%;
                width: 100%;
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .leftIcon {
                  width: 24px;
                  height: 24px;
                  text-align: center;
                  line-height: 24px;
                  font-size: 18px;
                  margin: 0 10px;
                }

                .chatTitle {
                  // margin:0 10px;
                  width: 250px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                }

                .editIcon {
                  position: absolute;
                  right: 2px;
                  bottom: 0;
                  height: 100%;
                  text-align: center;
                  line-height: 50px;
                  display: none;
                  background-color: #fff;
                  padding: 0 5px;

                  .edit {
                    color: orange;
                    margin-right: 10px;
                  }

                  .del {
                    color: red;
                  }
                }

                &:hover {
                  .editIcon {
                    display: block;
                  }

                }
              }

              .editing {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .editInput {
                  height: 100%;

                  /deep/.el-input__inner {
                    height: 100%;
                    border: 0 important;
                    padding-right: 50px;
                  }
                 /deep/ .el-input__suffix{
                    font-size: 18px!important;
                    display: flex;
                    align-items: center;
                    color: orange;
                    i{
                      margin-left: 8px;
                      &:last-child{
                        color: red;
                      }
                    }

                  }
                }
              }
            }
            .hitoryBrain{
              width: 280px;
              height: 70px;
              margin-bottom: 10px;
              background: #FFFFFF;
              padding: 8px;
              box-sizing: border-box;
              border-radius: 5px 5px 5px 5px;
              border: 1px solid #E6E6E6;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              position: relative;
              &:hover{
                .editBox{
                  display: block;
                }
              }
              .leftPhoto{
                width: 50px;
                height: 50px;
                box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2), inset 0px 2px 4px 0px #FFFFFF;
                border-radius: 10px 10px 10px 10px;
                border: 2px solid #FFFFFF;
                box-sizing: border-box;
                .upLoadImg{
                  border-radius: 10px 10px 10px 10px;
                }
              }
              .rightDescribe{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-left: 10px;
                .rightDescribeTop{
                  font-size: 16px;
                  font-family: Source Han Sans CN, Source Han Sans CN;
                  font-weight: 500;
                  color: #4D4D4D;
                  text-align: left;
                  width: 180px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .rightDescribeBottom{
                  font-size: 16px;
                  font-family: Source Han Sans CN, Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                  width: 180px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                }
              }
              .editBox{
                position: absolute;
                right: 6px;
                display: none;
                  background-color: #fff;
                  padding: 0 5px;
                .del{
                  color: red;

                }
              }
            }
          }

          .emptyDialog {
            margin: 50% auto;
            width: 200px;
            height: 200px;
            background-image: url('~@/assets/img/noMessage/noDialog.png');
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
          }
          .emptyBrain{
            margin: 50% auto 20%;
            width: 200px;
            height: 200px;
            background-image: url('~@/assets/img/noMessage/noBrain.png');
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
          }
        }

      }
    }

  }

  .rightDetial {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>
